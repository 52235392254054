<template>
  <div
    v-if="!!attributeName"
    :id="`${attributeName}-attribute`">
    <v-data-table
      :headers="tableHeaders"
      :items="attributeData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="itemsLength"
      :height="530"
      class="elevation-1 mb-5"
      hide-default-footer
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span
              v-if="attributeName === 'fabric' || attributeName === 'material'"
              class="capitalize">
              {{ attributeName }} Factories
            </span>
            <span
              v-else-if="attributeName === 'prototype-sub-categories'"
              class="capitalize">
              Sub-categories
            </span>
            <span
              v-else
              class="capitalize">
              {{ attributeName }}
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <search-box
            v-model="search"
            @on-search="searchAttribute($event)" />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-1 ml-2"
                v-bind="attrs"
                v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <validation-observer
                ref="attributeForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col
                        v-for="(form, index) in formData"
                        :key="index"
                        :cols="12">
                        <gw-text-field
                          v-model.trim="formData[index].name"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Name" />
                      </v-col>
                    </v-row>
                    <v-row v-if="createMulti && !isEdited">
                      <v-spacer />
                      <v-col cols="auto">
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="addForm()">
                          Add
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removeAttribute(item)" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PrototypeAttributeProvider from '@/resources/PrototypeAttributeProvider'
import FabricsFactoryProvider from '@/resources/FabricsFactoyProvider'
import MaterialsFactoryProvider from '@/resources/MaterialsFactoryProvider'
import SearchBox from '@/components/SearchBox.vue'

const PrototypeAttributeService = new PrototypeAttributeProvider()
const FabricsFactoryService = new FabricsFactoryProvider()
const MaterialFactoryService = new MaterialsFactoryProvider()

export default {
  components: { SearchBox },
  props: {
    attributeName: {
      type: String,
      requied: true,
      default: ''
    },
    createMulti: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    },
    itemsPerPage: {
      type: [String, Number],
      default: 10
    },
    sortBy: {
      type: String,
      default: 'id'
    },
    sortDesc: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true]
      },
      attributeData: [],
      isEdited: false,
      formData: [{}],
      textFieldRules: {
        required: true
      },
      getting: false,
      modalLoading: false,
      isFocus: false,
      search: ''
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    },
    paginationEnable () {
      return this.pageCount > 1
    },
    tableHeaders () {
      if (this.headers.length) {
        return [
          ...this.headers,
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            width: 60,
            align: 'end'
          }
        ]
      }

      return [
        {
          text: 'Name',
          value: 'name',
          width: 200,
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ]
    }
  },
  mounted () {
    this.options.itemsPerPage = this.itemsPerPage
    this.options.sortBy = [this.sortBy]
    this.options.sortDesc = [this.sortDesc]

    if (this.search === '') {
      this.search = this.$route.query?.search || ''
    }

    this.getAttribute(this.options)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    capitalize (value) {
      if (!value) {
        return ''
      }
      return value.toString().charAt(0).toUpperCase() + value.slice(1)
    },
    searchAttribute (value) {
      this.options.page = 1
      this.search = value

      this.$router.push({
        name: this.$route.name,
        query: { search: value }
      }).catch(() => {})
      this.getAttribute(this.options)
    },
    async getAttribute (options) {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          let dataSelector = []
          if (this.attributeName === 'fabric') {
            const { data } = await FabricsFactoryService.getAll({
              page: this.options.page,
              search: this.search
            })
            dataSelector = data
          } else if (this.attributeName === 'material') {
            const { data } = await MaterialFactoryService.getAll({
              page: this.options.page,
              search: this.search
            })
            dataSelector = data
          } else {
            const { data } = await PrototypeAttributeService.getPrototypeAttribute(this.attributeName, {
              ...options,
              search: this.search
            })
            dataSelector = data
          }
          this.itemsLength = dataSelector.total
          this.pageCount = dataSelector.pages
          this.attributeData = dataSelector.results
        } catch (error) {
          console.error('getAttribute: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    optionsUpdated () {
      this.getAttribute(this.options)
    },
    addForm () {
      this.formData.push({})
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.formData = [{}]
    },
    editItem (item) {
      this.isEdited = true
      this.formData = [{
        id: item.id,
        name: item.name
      }]
      this.dialog = true
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.attributeForm.validate()
      if (vaid) {
        let isSuscess = false

        let message = 'created'
        try {
          if (this.isEdited) {
            if (this.attributeName === 'fabric') {
              isSuscess = await FabricsFactoryService.updateOne(this.formData[0].id, { name: this.formData[0].name })
            } else if (this.attributeName === 'material') {
              isSuscess = await MaterialFactoryService.updateOne(this.formData[0].id, { name: this.formData[0].name })
            } else {
              isSuscess = await this.updateAttribute(this.formData[0])
            }
            message = 'updated'
          }
          if (!this.isEdited) {
            if (this.attributeName === 'fabric') {
              isSuscess = await FabricsFactoryService.createOne(this.formData[0])
            } else if (this.attributeName === 'material') {
              isSuscess = await MaterialFactoryService.createOne(this.formData[0])
            } else {
              isSuscess = await this.createAttribute(this.formData)
            }
          }
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
          console.error('saveAttribute', error)
        } finally {
          this.closeFormModal()
          if (isSuscess) {
            this.setSnackbar({
              value: true,
              message: this.$t(`snackbar.${this.capitalize(this.attributeName)} ${message}`),
              type: 'success'
            })
            this.getAttribute(this.options)
          }
        }
      }
      this.modalLoading = false
    },
    async createAttribute (items) {
      const { data } = await PrototypeAttributeService.createPrototypeAttribute(this.attributeName, {
        name: items[0].name
      })
      return !!data.id
    },
    async updateAttribute (item) {
      const { data } = await PrototypeAttributeService.updatePrototypeAttribute({
        path: this.attributeName,
        id: item.id
      }, item)
      return !!data.ok
    },
    async removeAttribute (item) {
      this.setModal({
        value: true,
        title: `Remove${this.capitalize(this.attributeName)}`,
        message: `Do you want to remove the ${this.attributeName}`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveAttribute(item.id)
      })
    },
    async confirmRemoveAttribute (id) {
      try {
        let dataSelector = {}
        if (this.attributeName === 'fabric' || this.attributeName === 'material') {
          if (this.attributeName === 'fabric') {
            const data = await FabricsFactoryService.deleteOne(id)
            dataSelector = data
          } else {
            const data = await MaterialFactoryService.deleteOne(id)
            dataSelector = data
          }
        } else {
          const data = await PrototypeAttributeService.deleteAttribute(this.attributeName, id)
          dataSelector = data
        }
        if (dataSelector.message === 'done') {
          this.setSnackbar({
            value: true,
            message: this.$t(`snackbar.${this.capitalize(this.attributeName)} removed`),
            type: 'success'
          })
          if (this.options.page > 1 && this.attributeData.length < 2) {
            this.options.page--
          } else {
            this.getAttribute(this.options)
          }
        } else {
          const error = {
            code: 400,
            message: `${this.capitalize(this.attributeName)} ID not found or status not change`
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveAttribute', error)
      }
    }
  }
}
</script>
