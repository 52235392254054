import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ProductAttributeProvider extends HttpRequest {
  constructor () {
    super(`${process.env.VUE_APP_MER_API}`)
  }

  getPrototypeAttribute (param, options) {
    this.setHeader(getAuthToken())
    return this.getByPagination(`${param}`, options)
  }

  createPrototypeAttribute (param, payload) {
    this.setHeader(getAuthToken())
    return this.post(`${param}`, payload)
  }

  updatePrototypeAttribute (param, payload) {
    this.setHeader(getAuthToken())
    return this.put(`${param.path}/${param.id}`, payload)
  }

  deleteAttribute (param, id) {
    this.setHeader(getAuthToken())
    return this.delete(`${param}/${id}`)
  }
}

export default ProductAttributeProvider
